import logo from './logo.svg';
import './App.css';
function App() {
  return (
      <div className="App">


        <nav id="navbar" class="navbar navbar-expand-lg fixed-top navbar-light" aria-label="Main navigation">
          <div class="container">



            <a class="navbar-brand logo-text" href="#"><img src="assets/images/logo.png" alt="Les Zaventures de Zoée" /></a>

            <button class="navbar-toggler p-0 border-0" type="button" id="navbarSideCollapse" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>

            <div class="navbar-collapse offcanvas-collapse" id="navbarsExampleDefault">
              <ul class="navbar-nav ms-auto navbar-nav-scroll">
                <li class="nav-item">
                  <a class="nav-link active" aria-current="page" href="#header">Accueil</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#social_network">Suivez-nous</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#about_syndrome">Syndrome du cri du chat</a>
                </li>

              </ul>
              <span class="nav-item">
                        <a class="btn-solid-sm" target="_blank" href="https://www.helloasso.com/associations/les-zaventures-de-zoe">Faire un don</a>
                    </span>
            </div>
          </div>
        </nav>

        <header id="header" class="header">
          <div class="container">
            <div class="row">
              <div class="col-lg-6 col-xl-5">
                <div class="text-container">
                  <h1 class="h1-large">Ca n’arrive pas qu’aux autres !</h1>
                  <p class="p-large">
                    Salut moi c’est Zoé, petite blonde aux yeux bleus d’un an. Je suis atteinte du Syndrome du Cri du Chat, il me manque un petit bout d’ADN au niveau du chromosome 5. Du coup j’ai un retard de développement et je serai sûrement en situation de handicap mais impossible de savoir pour l’instant à quel degré ni quel niveau. Comme j’ai été dépistée cet été, nous sommes encore en train de passer des tas d’examens pour savoir où j’en suis. Je suis patiente, adorable mais très têtue.
                  </p>
                  <div className="qrHome"><img src="assets/images/qrcode_hello_asso.png"
                                               alt="Faire un don"/></div>
                  <p className="p-large">
                    Je vous invite à découvrir mes Zaventures via les réseaux sociaux, ce sera comme de vraies aventures, mais un peu différentes, comme moi ! Et si vous voulez m’aider, n’hésitez pas à adhérer à mon association ou à faire un don.
                  </p>
                  <a class="btn-solid-lg" href="https://www.helloasso.com/associations/les-zaventures-de-zoe">Faire un don</a>
                </div>
              </div>
              <div class="col-lg-6 col-xl-7">
                <div class="image-container">
                  <img class="img-fluid" src="assets/images/zoe_header.jpg" alt="Zoé" width="600" />
                </div>
              </div>
            </div>
          </div>
        </header>

        <div id="social_network" class="cards-1 bg-dark">
          <div class="container">
            <div class="row">
              <div class="col-lg-12 ">
                <h2 class="text-light text-center">Suivez nous sur les réseaux sociaux</h2>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">


                <div class="card social-network">
                  <div class="card-icon">
                    <span class="fas fa-brands fa-instagram"></span>
                  </div>
                  <div class="card-body">
                     <a href="https://www.instagram.com/leszaventuresdezoe/">@leszaventuresdezoe</a>
                  </div>
                </div>

                <div class="card">
                  <div class="card-icon">
                    <span className="fas fa-brands fa-facebook"></span>
                  </div>
                  <div class="card-body">
                    <a href="https://www.facebook.com/Les-Zaventures-de-Zo%C3%A9-105802622274580">@Les-Zaventures-de-Zoé</a>
                  </div>
                </div>

                <div class="card">
                  <div class="card-icon">
                    <img src="assets/images/logo-helloasso-white.svg" height="50" alt="Hello asso"/>
                  </div>
                  <div class="card-body">
                    <a href="https://www.helloasso.com/associations/les-zaventures-de-zoe">Les Zaventures de Zoé</a>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
        <div id="about_syndrome" class="about_syndrome">
          <div class="container">
            <div class="row">
              <div class="col-lg-6 col-xl-7">
                <div class="image-container">
                  <img class="img-fluid" src="assets/images/cri_du_chat_syndrome.jpg" alt="alternative" />
                </div>
              </div>
              <div class="col-lg-6 col-xl-5">
                <div class="text-container text-center">
                  <div class="section-title">Aller plus loin</div>
                  <h2>Le Syndrome du cri du chat c’est quoi ?<br /><em>(en version simplifiée)</em></h2>
                  <p>Il s’agit d’une maladie rare, touchant un nouveau-né sur 50 000 environ.</p>
                  <p>Le Syndrome du cri du chat ou déletion 5p est une anomalie chromosomique résultant d'une délétion de taille variable de l'extrémité du bras court du chromosome 5.
                  </p>

                </div>
              </div>
            </div>
            <div class="row">

              <div className="col-lg-12">
                <div className="text-container text-justify">
                  <p>
                      Les enfants atteints du syndrome du cri du chat ont à la naissance un poids, une taille et un périmètre crânien plus petits que les autres enfants. Les nouveau-nés ont souvent du mal à s’alimenter, ils tètent mal, boivent très lentement, font des fausses routes et régurgitent beaucoup. Zoé a coché toutes ces cases !
                  </p>
                  <p>D’autres problèmes sont aussi rencontrés par les parents tels que des problèmes respiratoires, des problèmes de vision ainsi que des malformations cardiaques, rénales et intestinales congénitales. Une intervention chirurgicale peut être envisagée pour résoudre certaines de ces malformations. C’est ainsi que Zoé a été opérée du périnée et de l’anus avant même ses 4 mois de vie.

                  </p>
                  <p>  Cette anomalie chromosomique entraîne souvent mais pas toujours, outre le ton aigüe de la voix, une microcéphalie, un petit menton, un strabisme divergent, un retard mental souvent sévère, un retard souvent important de langage, et assez souvent des troubles du comportement. Pour Zoé, on a la microcéphalie donc il y aura surement une déficience mentale, pas de strabisme mais un épicanthus (la forme du coin interne de ces yeux), un vrai retard de langage (il faudra surement signer) et pour le reste c’est trop tôt pour le dire.
                  </p>
                  <p>  Le développement moteur est plus lent chez les enfants atteints. Des actions simples tels que se lever, tourner la tête, marcher, se font très lentement et demandent plus d’efforts. Les enfants sont capables de tenir assis et de marcher mais bien plus tard que la normal, et on voit bien avec Zoé que ces acquisitions sont vraiment difficiles.

                  </p>
                  <p> Il n’existe pas de traitement à proprement parler. Les complications associées peuvent être prises en charge. La prise en charge éducative et rééducative (kinésithérapie, psychomotricité, orthophonie) peut également améliorer sensiblement le pronostic. C’est ici que l’association entre en jeu !
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>



        <div class="footer bg-gray">
          <img class="decoration-circles" src="assets/images/decoration-circles.png" alt="alternative" />
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <h4>Vous voulez nous soutenir ou vous tenir au courant des Zaventures de Zoé ? Suivez-nous sur les réseaux sociaux !</h4>
                <div class="social-container">
                            <span class="fa-stack">
                                <a href="https://www.facebook.com/Les-Zaventures-de-Zo%C3%A9-105802622274580">
                                    <i class="fas fa-circle fa-stack-2x"></i>
                                    <i class="fab fa-facebook-f fa-stack-1x"></i>
                                </a>
                            </span>

                  <span class="fa-stack">
                                <a href="https://www.instagram.com/leszaventuresdezoe/">
                                    <i class="fas fa-circle fa-stack-2x"></i>
                                    <i class="fab fa-instagram fa-stack-1x"></i>
                                </a>
                            </span>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="copyright bg-gray">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-12 col-sm-12">
                <ul class="list-unstyled li-space-lg p-small">

                </ul>
              </div>
              <div class="col-lg-6 col-md-12 col-sm-12">
                <p class="p-small text-center" >Copyright © 2022 - <a href="#">Les Zaventures de Zoé</a></p>
              </div>

              <div class="col-lg-3 col-md-12 col-sm-12">
                <p class="p-small statement text-right">Hébergé par <a href="https://www.cmxconsulting.fr/" target="_blank">CMX Consulting</a></p>
              </div>
            </div>
          </div>
        </div>

        <button id="myBtn">
          <img src="assets/images/up-arrow.png" alt="alternative" />
        </button>

      </div>
  );
}

export default App;
